<template>
  <div>
    <b-modal
      id="modal-edit"
      size="lg"
      centered
      title="Edit Data Perusahaan Penempatan"
      header-bg-variant="warning"
      header-text-variant="light"
    >
      <b-form>
        <b-form-group label="Perusahaan" label-cols-md="3">
          <b-form-input
            :state="checkIfValid('namaPerusahaan')"
            type="text"
            v-model="$v.data.namaPerusahaan.$model"
          ></b-form-input>
        </b-form-group>
        <b-form-group label="Alamat" label-cols-md="3">
          <b-form-textarea
            rows="3"
            max-rows="6"
            :state="checkIfValid('alamatLengkap')"
            type="text"
            v-model="$v.data.alamatLengkap.$model"
          ></b-form-textarea>
        </b-form-group>

        <b-form-group label="No. Telepon" label-cols-md="3">
          <b-form-input
            :state="checkIfValid('nomorTeleponPerusahaan')"
            type="number"
            v-model="$v.data.nomorTeleponPerusahaan.$model"
          ></b-form-input>
        </b-form-group>

        <b-form-group label="Email" label-cols-md="3">
          <b-form-input
            :state="checkIfValid('emailPerusahaan')"
            type="email"
            v-model="$v.data.emailPerusahaan.$model"
          ></b-form-input>
        </b-form-group>

        <b-form-group label="Koordinat Lintang" label-cols-md="3">
          <b-form-input
            :state="checkIfValid('latitude')"
            type="text"
            v-model="$v.data.latitude.$model"
          ></b-form-input>
        </b-form-group>

        <b-form-group label="Koordinat Bujur" label-cols-md="3">
          <b-form-input
            :state="checkIfValid('longitude')"
            type="text"
            v-model="$v.data.longitude.$model"
          ></b-form-input>
        </b-form-group>

        <b-form-group label="PIC" label-cols-md="3">
          <b-form-input
            :state="checkIfValid('pic')"
            type="text"
            v-model="$v.data.pic.$model"
          ></b-form-input>
        </b-form-group>

        <b-form-group label="No. Telepon PIC" label-cols-md="3">
          <b-form-input
            :state="checkIfValid('nomorTeleponPic')"
            type="number"
            v-model="$v.data.nomorTeleponPic.$model"
          ></b-form-input>
        </b-form-group>

        <b-form-group label="Email PIC" label-cols-md="3">
          <b-form-input
            :state="checkIfValid('emailPic')"
            type="text"
            v-model="$v.data.emailPic.$model"
          ></b-form-input>
        </b-form-group>

        <b-form-group label="Jabatan PIC" label-cols-md="3">
          <b-form-input
            :state="checkIfValid('jabatanPic')"
            type="text"
            v-model="$v.data.jabatanPic.$model"
          ></b-form-input>
        </b-form-group>
      </b-form>
      <template #modal-footer>
        <b-button variant="secondary" @click="$bvModal.hide('modal-edit')">
          Tutup
        </b-button>
        <b-button
          variant="primary"
          :disabled="busy || !isDirty"
          @click="simpan()"
        >
          {{ button }}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import _ from "lodash";
import axios from "axios";
import { ipBackend } from "@/ipBackend";
import { validationMixin } from "vuelidate";
import { required, email } from "vuelidate/lib/validators";
export default {
  name: "modalInput",
  props: ["data"],
  data() {
    return {
      busy: false,
      button: "Simpan",
    };
  },
  computed: {
    formString() {
      return JSON.stringify(this.data, null, 4);
    },
    isValid() {
      return !this.$v.data.$invalid;
    },
    isDirty() {
      return this.$v.data.$anyDirty;
    },
  },
  mixins: [validationMixin],
  validations: {
    data: {
      namaPerusahaan: {
        required,
      },
      alamatLengkap: {
        required,
      },
      nomorTeleponPerusahaan: {
        required,
      },
      emailPerusahaan: {
        required,
        email,
      },
      latitude: {
        required,
      },
      longitude: {
        required,
      },
      pic: {
        required,
      },
      nomorTeleponPic: {
        required,
      },
      emailPic: {
        required,
        email,
      },
      jabatanPic: {
        required,
      },
    },
  },
  methods: {
    checkIfValid(fieldName) {
      const field = this.$v.data[fieldName];
      if (!field.$dirty) {
        return null;
      }
      return !(field.$invalid || field.$model === "");
    },
    simpan() {
      let vm = this;
      if (vm.isValid) {
        vm.busy = true;
        vm.button = "Mohon Tunggu";
        axios
          .post(ipBackend + "masterPerusahaan/update", vm.data)
          .then((res) => {
            if (res.data.message == "sukses") {
              vm.button = "Simpan";
              vm.busy = false;
              vm.$emit("alertFromChild", {
                variant: "success",
                msg: "BERHASIL EDIT MASTER PERUSAHAAN PENEMPATAN",
                showing: true,
              });
              vm.data = {};
              vm.$v.$reset();
              this.$bvModal.hide("modal-edit");
            } else {
              this.$bvModal.hide("modal-edit");
              vm.button = "Simpan";
              vm.busy = false;
              vm.$emit("alertFromChild", {
                variant: "danger",
                msg: _.toUpper(res.data.message),
                showing: true,
              });
            }
          })
          .catch((err) => {
            vm.button = "Simpan";
            vm.busy = false;
            this.$bvModal.hide("modal-edit");
            vm.$emit("alertFromChild", {
              variant: "danger",
              msg: "TERJADI KESALAHAN PADA SERVER",
              showing: true,
            });
          });
      }
    },
  },
};
</script>
